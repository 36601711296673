import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"

import RELOCATION_ICON from "../../images/content/services/Boxtruck.jpg"

const RelocationServices = () => {
  return (
    <>
      <Layout>
        <SEO
          title={`Laboratory Relocation & Moving Services`}
          description={`Surplus Solutions can make the process of relocating your laboratory or entire facility a seamless transition, with the help of our used equipment professional’s expert planning and execution. The process of relocating your used surplus equipment is a delicate one, and we provide a smooth process.`}
          />

        <SEO title="Laboratory Relocation &amp; Moving Services" />

        <div className="row column">
          <div className="row">
            <div className="medium-6 columns ">
              <div>
                <a href="/our-services/relocation-services">
                  <img src={RELOCATION_ICON} alt="Relocation Services" />
                </a>
              </div>
            </div>
            <div className="medium-6 columns">
              <h1>Relocation Services</h1>
              <hr />
              <p>
                SSLLC can make the process of relocating your Laboratory or
                entire Facility a seamless transition, with the help of our used
                equipment professional’s expert planning and execution. The
                process of relocating your used surplus equipment is a delicate
                one, and we at Surplus Solutions, take pride in the care that
                goes into the process.
              </p>
            </div>
          </div>
          <hr className="bg-blue mt6 mb8" />
          <p>
            Many companies have never moved equipment, whether a single piece or
            an entire facility. We’ve seen companies devastated by improper
            handling of their precious assets. Without the industry experience
            of how to properly execute a move, your equipment can be at risk.
          </p>
          <div>
            Our relocation services provide you with a ‘soup to nuts’ execution
            of your move, including:
          </div>
          <ul>
            <li>
              Pre-move planning and consulting
              <br />{" "}
            </li>
            <li>
              Logistics
              <br />{" "}
            </li>
            <li>
              Rigging
              <br />{" "}
            </li>
            <li>
              Packaging and crating
              <br />{" "}
            </li>
            <li>
              Loading, unloading and set-up
              <br />{" "}
            </li>
            <li>
              Storage
              <br />{" "}
            </li>
            <li>Transport</li>
          </ul>
          <div>Benefits to using Surplus Solutions for your relocation:</div>
          <ul>
            <li style={{"margin-left": "20px"}}>
              <strong>Safety First</strong>. In each move, we have a dedicated
              fleet of AirRide trucks to ensure safe delivery of your assets.
              You can’t trust your instruments and equipment to just any fleet.
              AirRide trucks utilize the best suspension technology available,
              and provide our clients with complete peace-of-mind.
            </li>
            <li style={{"margin-left": "20px"}}>
              <strong>Save Money.</strong> Surplus Solutions provides a
              comprehensive solution to your relocation, eliminating the need to
              work with multiple vendors and subcontractors to complete your
              transition. Whether it’s our consulting services or our storage
              rates at our warehouse, Surplus Solutions will provide highly
              competitive pricing.
            </li>
            <li style={{"margin-left": "20px"}}>
              <strong>Experience and Expertise.</strong> We understand what it
              takes to complete a relocation, and we make sure that every last
              detail is taken care of. We provide an experienced team that
              manages your project each step of the way. Leave the details to
              the experts!
            </li>
          </ul>
          <p>&nbsp;</p>
        </div>
      </Layout>
    </>
  )
}

export default RelocationServices
